body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}
.card-header {
	display: flex;
}
.container-fluid {
	padding-left: 0px !important;
	padding-right: 0px  !important;
}
.strikeout {
    text-decoration: none;
    background-image: linear-gradient(transparent 9px,#000000 9px,#000000 11px,transparent 11px);
}
.header {
    position: fixed;
    top: 0;
    left: 'auto';
    right: 0;
    width: 100%;
    z-index: 1000;
}
.content {
    margin-top: 120px;
}
.uppy-DragDrop-inner {
    border: 2px dashed !important;
    border-color: #adadad !important;
    max-width: 300px;
    padding: 20px 20px !important;
    width: 100;
}
.uppy-StatusBar:not(.is-waiting) {
    margin-top: 10px;
    max-width: 300px;
}
.error {
    padding-top: 5px;
    bottom: 3px;
    font-size: 12px;
    line-height: 12px;
    color: rgb(244, 67, 54);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.error-bar {
    padding-top: 10px;
    border-top: none rgb(244, 67, 54);
    border-left: none rgb(244, 67, 54);
    border-right: none rgb(244, 67, 54);
    border-bottom: 2px solid rgb(244, 67, 54);
    bottom: 8px;
    box-sizing: content-box;
    margin: 0px;
    width: 256px;
    transform: scaleX(1);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.signature_box {
    border: 2px dashed !important;
    border-color: #adadad !important;
    margin-bottom: 10px;
}
.floatingBadge {
    position: absolute;
    background: #ff253a;
    height: 0.8em;
    top: 0.8rem;
    right: 0.8rem;
    width: 0.8em;
    text-align: center;
    line-height: 0.8em;
    font-size: 0.8em;
    border-radius: 50%;
    color: white;
    border: 1px solid #ff253a;
 }
 .strikethrough {
    position: relative;
}
.strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 3px solid;
    border-color: inherit;
    color: black;
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -ms-transform:rotate(-45deg);
    -o-transform:rotate(-45deg);
    transform:rotate(-45deg);
}
.notes {
    padding: 10px;
    background: rgb(147, 143, 143);
}